import {customerActionTypes} from './customerTypes';

const initialState = {
  customerCreateQueryData: {
    accountId: 0,
    address: '',
    operationDetail: '',
    profits: [],
    startDate: '',
    taxAdministration: '',
    taxId: '',
    title: '',
  },
};

export default (
  state = {...initialState},
  action: {type: string; payload: any} = {type: '', payload: {}},
): typeof initialState => {
  switch (action.type) {
    case customerActionTypes.SET_CUSTOMER_CREATE_QUERY_DATA:
      return {...state, customerCreateQueryData: action.payload};
    case customerActionTypes.RESET_CUSTOMER_CREATE_QUERY_DATA:
      return {...state, customerCreateQueryData: initialState.customerCreateQueryData};

    default:
      return state;
  }
};
