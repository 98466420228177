import React from 'react';
import {Row, Col, Form, Input} from 'antd';
import {Link} from 'react-router-dom';
import Button from '../../components/Button';
import LoginStyles from './styles';
import {useAppDispatch, useAppSelector} from '../../hooks/useRedux';
import {login} from '../../store/user/userActions';
import paths from '../../routes/paths';
import Text from '../../components/Text';

interface LoginValues {
  email: string;
  password: string;
}

const Login: React.VFC = () => {
  const dispatch = useAppDispatch();
  const isLoginLoading = useAppSelector(({user}) => user.isLoginLoading);

  const onLogin = (userCredentials: LoginValues) => {
    dispatch(login(userCredentials));
  };

  return (
    <LoginStyles>
      <Row className="mt-big" justify="center">
        <Col xs={24} md={12} lg={8}>
          <Text type="h1" weight="bold">
            Kullanıcı Girişi
          </Text>

          <Form name="login" onFinish={onLogin} layout="vertical" requiredMark={false}>
            <Form.Item
              name="email"
              label="E-posta Adresi"
              rules={[
                {type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!'},
                {
                  required: true,
                  message: 'Lütfen e-posta adresi giriniz!',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              label="Şifre"
              rules={[
                {
                  required: true,
                  message: 'Lütfen şifrenizi giriniz!',
                },
              ]}
            >
              <Input.Password placeholder="Şifre" />
            </Form.Item>

            <Form.Item className="m-0">
              <Button
                className="mt"
                type="primary"
                htmlType="submit"
                size="large"
                loading={isLoginLoading}
                block
              >
                Giriş Yap
              </Button>
            </Form.Item>
          </Form>

          <div className="mt-big">
            <Link to={paths.passwordRecovery}>
              <Text type="h4" color="primary" underlined>
                Şifremi Unuttum
              </Text>
            </Link>
          </div>
        </Col>
      </Row>
    </LoginStyles>
  );
};

export default Login;
