import {TableProps} from 'antd';
import React, {FC} from 'react';
import StyledTable from './styles';

const Table: FC<TableProps<any>> = (props) => {
  return (
    <StyledTable
      rowClassName={(record, idx) => (idx % 2 === 0 ? 'light-smoke-background ' : '')}
      {...props}
    />
  );
};

export default Table;
