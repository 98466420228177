import {createGlobalStyle} from 'styled-components';

/* - INDEX -
 * COLOR
 * SPACING
 * TEXT
 */

export default createGlobalStyle`
  // COLOR
  .primary{
    color: ${({theme}) => theme.colors.primary};
  }
  .secondary{
    color: ${({theme}) => theme.colors.secondary};
  }
  
  .background {
    background: ${({theme}) => theme.colors.background};
  }
  .light-smoke-background {
    background: ${({theme}) => theme.colors.lightSmoke};
  }

  .shadow{
    box-shadow: ${({theme}) => theme.main.shadow};
  }
  .shadow-hover{
    box-shadow: ${({theme}) => theme.main.shadowHover};
  }

  .opacity-50{
    opacity: 0.5;
  }
  .opacity-40{
    opacity: 0.4;
  }

  .cursor-pointer{
    cursor: pointer;
  }
  
  .hover-scale{
    transition: transform ${({theme}) => theme.main.defaultAnimation};
    
    &:hover{
      transform: scale(1.05);
    }
  }

  .overflow-visible{
    overflow: visible;
  }

  .radius{
    border-radius: ${({theme}) => theme.main.borderRadius}px;
  }


  // SPACING
  .w-100{
    width: 100%;
  }
  .h-100{
    height: 100%;
  }
  .page-content-padding{
    padding-left: ${({theme}) => theme.pageContent.paddingHorizontal}px;
    padding-right: ${({theme}) => theme.pageContent.paddingHorizontal}px;
  }
  
  .d-block{
    display: block;
  }
  .d-inline{
    display: inline;
  }
  .d-inline-block{
    display: inline-block;
  }
  .d-flex{
    display: flex;
  }

  .flex-1{
    flex: 1;
  }
  .flex-2{
    flex: 1;
  }
  .flex-grow-1{
    flex-grow: 1;
  }

  .align-center{
    align-items: center;
  }
  .align-start{
    align-items: flex-start;
  }
  .align-end{
    align-items: flex-end;
  }

  .justify-center{
    justify-items: center;
  }
  .justify-start{
    justify-items: flex-start;
  }
  .justify-end{
    justify-items: flex-end;
  }
  .justify-between{
    justify-content: space-between;
  }

  .m{
    margin: ${({theme}) => theme.main.margin}px !important;
  }
  .m-auto{
    margin: auto !important;
  }
  .m-0{
    margin: 0px !important;
  }
  .m-auto{
    margin:  auto !important;
  }
  .mx{
    margin-right: ${({theme}) => theme.main.margin}px !important;
    margin-left: ${({theme}) => theme.main.margin}px !important;
  }
  .ml{
    margin-left: ${({theme}) => theme.main.margin}px !important;
  }
  .mr{
    margin-right: ${({theme}) => theme.main.margin}px !important;
  }
  .my{
    margin-top: ${({theme}) => theme.main.margin}px !important;
    margin-bottom: ${({theme}) => theme.main.margin}px !important;
  }
  .my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mt{
    margin-top: ${({theme}) => theme.main.margin}px !important;
  }
  .mb{
    margin-bottom: ${({theme}) => theme.main.margin}px !important;
  }
  .m-big{
    margin: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mx-big{
    margin-right: ${({theme}) => theme.main.marginBig}px !important;
    margin-left: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mr-big{
    margin-right: ${({theme}) => theme.main.marginBig}px !important;
  }
  .ml-big{
    margin-left: ${({theme}) => theme.main.marginBig}px !important;
  }
  .my-big{
    margin-top: ${({theme}) => theme.main.marginBig}px !important;
    margin-bottom: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mb-big{
    margin-bottom: ${({theme}) => theme.main.marginBig}px !important;
  }
  .mt-big{
    margin-top: ${({theme}) => theme.main.marginBig}px !important;
  }

  .p{
    padding: ${({theme}) => theme.main.padding}px !important;
  }
  .p-auto{
    padding: auto !important;
  }
  .p-0{
    padding: 0px !important;
  }
  .px{
    padding-right: ${({theme}) => theme.main.padding}px !important;
    padding-left: ${({theme}) => theme.main.padding}px !important;
  }
  .pl{
    padding-left: ${({theme}) => theme.main.padding}px !important;
  }
  .pr{
    padding-right: ${({theme}) => theme.main.padding}px !important;
  }
  .py{
    padding-top: ${({theme}) => theme.main.padding}px !important;
    padding-bottom: ${({theme}) => theme.main.padding}px !important;
  }
  .pt{
    padding-top: ${({theme}) => theme.main.padding}px !important;
  }
  .pb{
    padding-bottom: ${({theme}) => theme.main.padding}px !important;
  }
  .p-big{
    padding: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .px-big{
    padding-right: ${({theme}) => theme.main.paddingBig}px !important;
    padding-left: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pl-big{
    padding-left: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pr-big{
    padding-right: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .py-big{
    padding-top: ${({theme}) => theme.main.paddingBig}px !important;
    padding-bottom: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pb-big{
    padding-bottom: ${({theme}) => theme.main.paddingBig}px !important;
  }
  .pt-big{
    padding-top: ${({theme}) => theme.main.paddingBig}px !important;
  }


  // TEXT
  .bold{
    font-weight: 600 !important;
  }
  .thin{
    font-weight: 300;
  }
  .regular{
    font-weight: 400;
  }
  .light{
    color: ${({theme}) => theme.colors.light};
  }
  .dark{
    color: ${({theme}) => theme.colors.dark};
  }
  .text-center{
    text-align: center;
  }
  .text-l{
    text-align: left;
  }
  .text-r{
    text-align: right;
  }
  // IMAGE
  .cover {
    object-fit: cover;
  }

  // ANTD
  .ant-input-number-handler {
    display: none;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`;
