import {ICustomerQuery} from '../../Api/Customer/types';

export const customerActionTypes = {
  SET_CUSTOMER_CREATE_QUERY_DATA: 'SET_CUSTOMER_CREATE_QUERY_DATA',
  RESET_CUSTOMER_CREATE_QUERY_DATA: 'RESET_CUSTOMER_CREATE_QUERY_DATA',
};

export interface ISetCustomerQuery {
  type: string;
  payload: ICustomerQuery;
}
