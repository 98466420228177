import React, {useState} from 'react';
import {Col, notification, Row} from 'antd';
import {Link, useHistory, useLocation} from 'react-router-dom';
import ContentContainer from '../../ContentContainer';
import {HeaderContainer, Logo} from './styles';
import Button from '../../Button';
import AccountDropdown from '../../AccountDropdown';
import {useAppSelector} from '../../../hooks/useRedux';
import LogoPng from '../../../assets/logo.png';
import customerApi from '../../../Api/Customer';
import PATHS from '../../../routes/paths';
import StyledAutoComplete from '../../StyledAutoComplete';
import {ICustomer} from '../../../Api/Customer/types';

const pages = [
  {text: 'Müşterilerim', path: PATHS.customer},
  {text: 'Müşteri Tanımla', path: PATHS.customerCreate},
  {text: 'Firma Analizi Başlat', path: PATHS.login, disabled: true},
];

const Header: React.VFC = () => {
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname;

  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const {isLoggedIn} = useAppSelector((state) => state.user);

  const headerLayout = isLoggedIn ? 'space-between' : 'center';

  const searchCustomer = async (search: string) => {
    const {data} = await customerApi.fetchCustomers({search});
    setCustomers(data);
    return data.map((customer) => ({...customer, value: customer.id, label: customer.companyName}));
  };
  const onSelectCustomer = (customerId: number) => {
    const customer = customers.find(({id}) => id === customerId);

    if (!customer?.taxOffice || !customer.taxNumber) {
      notification.warning({
        message: 'Eksik Bilgi',
        description: 'Müşterinin VKN/TCKN ve Vergi Dairesi bilgilerini kontrol ediniz.',
      });
    } else {
      history.push(PATHS.getCustomerDetailPath(customer?.taxOffice, customer.taxNumber));
    }
  };

  return (
    <HeaderContainer>
      <ContentContainer className="h-100" verticalPadding={false} margin="auto">
        <Row className="h-100" justify={headerLayout} align="middle" gutter={20}>
          <Col>
            <Link to={PATHS.default}>
              <Logo src={LogoPng} alt="logo" />
            </Link>
          </Col>

          {isLoggedIn && (
            <>
              <Col>
                {pages.map(({text, path, disabled}, idx) => (
                  <Link key={`link-${idx}`} to={path}>
                    <Button
                      disabled={disabled}
                      className="mr"
                      type={path === currentPath ? 'primary-faded' : 'link'}
                      size="large"
                    >
                      {text}
                    </Button>
                  </Link>
                ))}
              </Col>

              <Col span={6}>
                <StyledAutoComplete
                  className="w-100"
                  placeholder="Müşteri ara…"
                  value={null}
                  showArrow={false}
                  onSelect={({value}: {value: number}) => onSelectCustomer(value)}
                  fetchOptions={searchCustomer}
                />
              </Col>

              <Col>
                <AccountDropdown />
              </Col>
            </>
          )}
        </Row>
      </ContentContainer>
    </HeaderContainer>
  );
};

export default Header;
