import React, {useEffect, useState} from 'react';
import {Row, Spin} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import {jsPDF as JsPdf} from 'jspdf';
import html2canvas from 'html2canvas';
import {useDispatch} from 'react-redux';
import ContentContainer from '../../../components/ContentContainer';
import Text from '../../../components/Text';
import customerApi from '../../../Api/Customer';
import {ICustomerQuery} from '../../../Api/Customer/types';
import paths from '../../../routes/paths';
import {useAppSelector} from '../../../hooks/useRedux';
import {
  fetchCities,
  fetchCurrencyTypes,
  fetchPaymentTypes,
} from '../../../store/globals/globalsActions';
import {CustomerInfoContainer} from './style';
import GeneralInfo from './GeneralInfo';
import TradeRegistryInfo from './TradeRegistryInfo';
import {setCustomerQueryData} from '../../../store/customer/customerActions';
import InsuranceInfo from './InsuranceInfo';
import WarrantInfo from './WarrantInfo';
import WorkingConditionInfo from './WorkingConditionInfo';
import Icon from '../../../components/Icon';

const CustomerInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams() as {taxOffice: string; taxNumber: string};

  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState<ICustomerQuery | any>();
  const {isLoadingPaymentTypes, isLoadingCurrencyTypes} = useAppSelector((state) => state.globals);

  const fetchCustomerDetail = async () => {
    try {
      setIsLoading(true);
      const detail = await customerApi.queryCustomer(params.taxNumber, params.taxOffice);
      setCustomer(detail);
      console.log(customer);
      dispatch(setCustomerQueryData(detail));
      setIsLoading(false);
    } catch {
      history.push(paths.customer);
    }
  };

  useEffect(() => {
    fetchCustomerDetail();
  }, [params]);

  useEffect(() => {
    dispatch(fetchCities());
    dispatch(fetchPaymentTypes());
    dispatch(fetchCurrencyTypes());
  }, []);

  const domElement: any = document.querySelector('#print-pdf');
  const handlePdf = () => {
    html2canvas(domElement, {
      onclone: (doc) => {
        console.log('Item Cloned', doc);
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      const pdf = new JsPdf();
      const width = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * width) / canvas.width; // your own stuff to calc the format you want

      pdf.addImage(imgData, 'JPEG', 5, 0, width, imgHeight);
      pdf.save('a4.pdf');
    });
  };

  return (
    <Spin spinning={isLoading || isLoadingPaymentTypes || isLoadingCurrencyTypes}>
      <div id="print-pdf">
        <ContentContainer>
          <CustomerInfoContainer>
            <Row className="mb-big" align="middle" justify="center" gutter={20}>
              <div>
                <Text type="h2" className="main-title">
                  {customer?.title}
                </Text>
                <Text type="h2" className="main-title">
                  VKN : {customer?.taxId}{' '}
                  <Icon className="download-icon" onClick={handlePdf} icon="file-download" />
                </Text>
              </div>
            </Row>
            <Row gutter={[10, 40]}>
              <GeneralInfo />
              <TradeRegistryInfo />
              <WarrantInfo />
              <InsuranceInfo />
              <WorkingConditionInfo />
            </Row>
          </CustomerInfoContainer>
        </ContentContainer>
      </div>
    </Spin>
  );
};

export default CustomerInfo;
