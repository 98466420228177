export const INSURANCE_COMPANIES_BY_NAME = {
  EULAR_HERMES: {value: 218, value1: 0, label: 'Eular Hermes'},
  COFACE: {value: 219, value1: 0, label: 'Coface'},
  ATRADIUS: {value: 220, value1: 0, label: 'Atradius'},
};

export const CURRENCY = {
  TL: {value: 11, value1: 0, label: 'TL'},
  USD: {value: 12, value1: 0, label: 'USD'},
  EUR: {value: 13, value1: 0, label: 'EUR'},
  GBP: {value: 14, value1: 0, label: 'GBP'},
};

export const CREATE_CUSTOMER_STEPS = {
  INFO: {
    key: 'INFO',
    title: 'Temel Bilgile',
    icon: 'home',
  },
  RECORD_INFO: {
    key: 'RECORD_INFO',
    title: 'Ticaret Sicil Gazetesi Bilgileri',
    icon: 'paper',
  },
  WARRANT_INFO: {
    key: 'WARRANF_INFO',
    title: 'Teminat Bilgileri',
    icon: 'check-circle',
  },
  INSURANCE_INFO: {
    key: 'INSURANCE_INFO',
    title: 'Alacak Sigortası Bilgileri',
    icon: 'umbrella',
  },
  FINDEKS_INFO: {
    key: 'FINDEKS_INFO',
    title: 'Findeks Bilgileri',
    icon: 'increase-circle',
  },
  FINANCIAL_INFO: {
    key: 'FINANCIAL_INFO',
    title: 'Mali Tablolar',
    icon: 'rotation',
  },
  LEGAL_DOCUMENTS: {
    key: 'LEGAL_DOCUMENTS',
    title: 'Legal Evraklar',
    icon: 'cards',
  },
  WORKING_CONDITIONS_INFO: {
    key: 'WORKING_CONDITIONS_INFO',
    title: 'Çalışma Koşulları',
    icon: 'bag',
  },
};
