import {tciApi} from '..';
import {IResetPasswordRequestBody, IUser} from './types';

export default {
  login(username: string, password: string): Promise<IUser> {
    return tciApi.post('/user/login', {username, password});
  },
  fetchUser(): Promise<IUser> {
    return tciApi.get('/user/login');
  },
  sendResetPasswordEmail(userEmail: string): Promise<unknown> {
    return tciApi.post('/user/forgotpassword', {userEmail});
  },
  resetPassword({token, password, passwordAgain}: IResetPasswordRequestBody): Promise<unknown> {
    return tciApi.post('/user/resetpassword', {token, password, passwordAgain});
  },
};
