import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useLocation, RouteProps} from 'react-router-dom';
import {useAppSelector} from '../hooks/useRedux';

import Login from '../pages/Login';
import PasswordRecovery from '../pages/Login/PasswordRecovery';
import ResetPassword from '../pages/Login/ResetPassword';
import Dashboard from '../pages/Dashboard';
import CustomerList from '../pages/Customer/CustomerList';
import CustomerDetail from '../pages/Customer/CustomerDetail';
import CustomerCreate from '../pages/Customer/CustomerCreate';
import CustomerCreateBulk from '../pages/Customer/CustomerCreateBulk';
import CustomerInfo from '../pages/Customer/CustomerInfo';
import User from '../pages/User';
import paths from './paths';

export const ProtectedRoute: React.FC<RouteProps> = ({...props}) => {
  const {isLoggedIn} = useAppSelector(({user}) => user);

  return isLoggedIn ? <Route {...props} /> : <Redirect to={paths.login} />;
};
export const GuestRoute: React.FC<RouteProps> = ({...props}) => {
  const {isLoggedIn} = useAppSelector(({user}) => user);

  return !isLoggedIn ? <Route {...props} /> : <Redirect to="/" />;
};

const Routes: React.VFC = () => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => window.scroll({top: 0, behavior: 'smooth'}));
  }, [location]);

  return (
    <Switch>
      <Route path={paths.resetPassword} exact component={ResetPassword} />

      <GuestRoute path={paths.login} exact component={Login} />
      <GuestRoute path={paths.passwordRecovery} exact component={PasswordRecovery} />

      <ProtectedRoute path={paths.user} exact component={User} />
      <ProtectedRoute path={paths.default} exact component={Dashboard} />
      <ProtectedRoute path={paths.customer} exact component={CustomerList} />
      <ProtectedRoute path={paths.customerDetail} exact component={CustomerDetail} />
      <ProtectedRoute path={paths.customerCreate} exact component={CustomerCreate} />
      <ProtectedRoute path={paths.customerCreateBulk} exact component={CustomerCreateBulk} />
      <ProtectedRoute path={paths.customerInformation} exact component={CustomerInfo} />

      <Redirect to={paths.default} />
    </Switch>
  );
};

export default Routes;
