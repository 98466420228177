/* eslint-disable react/no-unstable-nested-components */
import React, {useEffect, useState} from 'react';
import {pdfjs} from 'react-pdf';
import {Upload as UploadAntd} from 'antd';
import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';
import {PreviewContainerStyles} from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// interface UploadProps extends UploadPropsAntd {
//   label?: string;
//   desc?: string;
//   onRemove?: () => void;
// }
// interface UploadButtonProps extends ButtonProps {
//   label?: string;
//   desc?: string;
// }
// interface UploadPreviewContainerProps {
//   file?: File | RcFile | UploadFile<any>;
//   onRemove?: () => void;
// }

// const UploadButton: FC<UploadButtonProps> = ({label, desc}) => {
const UploadButton = ({label, desc}) => {
  return (
    <Button type="primary-faded" style={{height: 'initial', minHeight: 100, minWidth: 110}}>
      <Icon className="d-block m-auto" icon="plus-circle" color="primary" size="lg" />
      <Text color="primary" bold style={{marginTop: 3}}>
        {label}
      </Text>
      <Text type="h5" color="smoke">
        {desc}
      </Text>
    </Button>
  );
};

// const UploadPreviewContainer: FC<UploadPreviewContainerProps> = ({file, onRemove}) => {
const UploadPreviewContainer = ({file, onRemove}) => {
  const [fileSrc, setFileSrc] = useState(false);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [pdfPageCount, setPdfPageCount] = useState(0);

  const fileName = file?.name || 'dosya';
  // const isPdf = file?.type === 'application/pdf';

  const previewFile = () => {
    // if (file) {
    //   const fileUrl = URL.createObjectURL(file);
    //   window.open(fileUrl);
    // }
    window.open(fileSrc);
    // setIsModalVisible(true);
  };

  // const handleLoadPdf = ({numPages}) => {
  //   setPdfPageCount(numPages);
  // };

  useEffect(() => {
    if (file) {
      const src = URL.createObjectURL(file);
      setFileSrc(src);
    } else {
      setFileSrc('');
    }
  }, [file]);

  return (
    <PreviewContainerStyles>
      <Icon
        className="d-block m-auto cursor-pointer"
        icon={['far', 'times-circle']}
        color="red"
        size="2x"
        onClick={onRemove}
      />
      <Text className="mt" align="center" style={{marginTop: 3}} bold block>
        {fileName}
      </Text>
      <Button type="link" size="small" onClick={previewFile} block>
        <Text type="h5" color="primary" underlined>
          İncele
        </Text>
      </Button>

      {/* <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={false}
        closeIcon={() => null}
        bodyStyle={{padding: 0, borderRadius: 10}}
      >
        {isPdf ? (
          <Document
            file={{url: fileSrc}}
            onLoadSuccess={handleLoadPdf}
            onLoadError={(err) => console.log(err)}
          >
            {[...new Array(pdfPageCount)].map((_, idx) => (
              <Page key={`page-number-${idx + 1}`} pageNumber={idx + 1} />
            ))}
          </Document>
        ) : (
          <Image src={fileSrc} width="100%" preview={false} />
        )}
      </Modal> */}
    </PreviewContainerStyles>
  );
};

// const Upload: FC<UploadProps> = ({label, desc, fileList, onRemove, ...props}) => {
const Upload = ({label = '', desc = '', fileList = [], ...props}) => {
  let file = fileList?.[0];
  file = file?.originFileObj || file;

  const handleImageUpload = () => {
    return false; // to prevent automatic request of ANTD Upload component
  };

  return (
    <div style={{height: 100}}>
      <UploadAntd
        fileList={fileList}
        itemRender={() => <UploadPreviewContainer file={file} onRemove={props.onRemove} />}
        beforeUpload={handleImageUpload}
        {...props}
      >
        {fileList?.length ? null : <UploadButton label={label} desc={desc} />}
      </UploadAntd>
    </div>
  );
};

export default Upload;
