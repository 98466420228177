import React, {FC} from 'react';
import {useTheme} from 'styled-components';
import {
  faAngleDown,
  faCheckCircle,
  faPlusCircle,
  faExclamationCircle,
  faFilePdf,
  faPen,
  faUser,
  faDownload,
  faFileDownload,
  faSearch,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons';

import {IconDefinition, library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome';
import {
  lock,
  home,
  bag,
  cards,
  paper,
  rotation,
  umbrella,
  userCircle,
  checkCircle,
  increaseCircle,
  search,
} from '../../assets/icons';
import {Color} from '../../theme';

library.add(
  faUser as IconDefinition,
  faAngleDown as IconDefinition,
  faCheckCircle as IconDefinition,
  faPen as IconDefinition,
  faFilePdf as IconDefinition,
  faExclamationCircle as IconDefinition,
  faTimesCircle as IconDefinition,
  faPlusCircle as IconDefinition,
  faDownload as IconDefinition,
  faFileDownload as IconDefinition,
  faSearch as IconDefinition,
  faUsers as IconDefinition,
);
const customIcons: Record<string, string> = {
  lock,
  home,
  bag,
  cards,
  paper,
  rotation,
  umbrella,
  search,
  'check-circle': checkCircle,
  'increase-circle': increaseCircle,
  'user-circle': userCircle,
};

interface IconProps extends FontAwesomeIconProps {
  color?: Color;
  className?: string;
}

const Icon: FC<IconProps> = ({color, ...props}) => {
  const theme = useTheme();
  const themedColor = theme.getThemedColor(color);

  return <FontAwesomeIcon color={themedColor} {...props} />;
};

type CustomIcon = keyof typeof customIcons;
interface CustomIconProps {
  icon: CustomIcon;
  height?: number;
  width?: number;
  className?: string;
  large?: boolean;
}
export const CustomIcon: FC<CustomIconProps> = ({icon, height, width, className, large}) => (
  <img
    src={customIcons[icon]}
    height={large ? 40 : height}
    width={large ? 40 : width}
    className={className}
    alt={`icon-${icon}`}
  />
);

export default Icon;
