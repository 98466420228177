export const decimalSeperator = ','; // 0,12
export const integerSeperator = '.'; // 100.000

export const convertToDotSeperated = (price: string | number) => {
  const validString = price.toString();
  if (!validString) return '0';

  const floatString = parseFloat(validString).toFixed(2).replace('.', decimalSeperator);
  return floatString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, integerSeperator);
};

export const convertNumberDotSeperated = (number: string | number = 0) => {
  const validNumberString = number ? number.toString() : '';
  if (!validNumberString) return '0';
  return validNumberString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, integerSeperator);
};

export const convertDotSeperatedToNumberString = (dotSeperated = '') => {
  const [integer = '', decimal = ''] = dotSeperated.split(decimalSeperator);
  const number = integer.split('.').join('');

  if (decimal) return `${number}.${decimal}`;

  return number;
};
export const convertDotSeperatedToFloat = (dotSeperated = '') =>
  parseFloat(convertDotSeperatedToNumberString(dotSeperated));
