/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import HttpService from '../services/HttpService';

const apuUrl = process.env.REACT_APP_API_URL;
const urlExtension = 'api/v1';

export const tciApi = new HttpService({
  baseURL: apuUrl
    ? `${apuUrl}/${urlExtension}`
    : `https://service-${window.location.hostname}/${urlExtension}`,
});
