/* eslint @typescript-eslint/no-explicit-any: "off" */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import Interceptor from './Interceptor';

class HttpService {
  axiosInstance: AxiosInstance;
  interceptor: any;

  constructor(options: AxiosRequestConfig) {
    this.axiosInstance = axios.create(options);
    this.interceptor = new Interceptor(this.axiosInstance);
  }

  async get(url: string, config?: AxiosRequestConfig) {
    const response: any = await this.axiosInstance.get(url, config);
    return response;
  }

  async post(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig) {
    const response: any = await this.axiosInstance.post(url, payload, config);
    return response;
  }

  async put(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig) {
    const response: any = await this.axiosInstance.put(url, payload, config);
    return response;
  }

  async patch(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig) {
    const response: any = await this.axiosInstance.patch(url, payload, config);
    return response;
  }

  async delete(url: string, config?: AxiosRequestConfig) {
    const response: any = await this.axiosInstance.delete(url, config);
    return response;
  }

  async downloadFile(url: string, config?: AxiosRequestConfig) {
    const response: any = await this.axiosInstance.get(url, {responseType: 'blob', ...config});

    const fileUrl = URL.createObjectURL(
      new Blob([response], {
        type: 'application/vnd.ms-excel',
      }),
    );
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'dosya');
    document.body.appendChild(link);
    link.click();

    return response;
  }
}

export default HttpService;
