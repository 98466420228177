import {ICustomerQuery} from '../../Api/Customer/types';
import {customerActionTypes} from './customerTypes';

// eslint-disable-next-line import/prefer-default-export
export const setCustomerQueryData = (customerQueryData: ICustomerQuery) => ({
  type: customerActionTypes.SET_CUSTOMER_CREATE_QUERY_DATA,
  payload: customerQueryData,
});

export const resetCustomerQueryData = () => ({
  type: customerActionTypes.RESET_CUSTOMER_CREATE_QUERY_DATA,
});
