import {Input, notification} from 'antd';
import React, {useEffect, useState, VFC} from 'react';
import {useHistory} from 'react-router-dom';
import ContentContainer from '../../../components/ContentContainer';
import Text from '../../../components/Text';
import Table from '../../../components/Table';
import PATHS from '../../../routes/paths';
import customerApi from '../../../Api/Customer';
import {ICustomer} from '../../../Api/Customer/types';

const CustomerList: VFC = () => {
  const history = useHistory();
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(true);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [pagingPage, setPagingPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  const tableCols = [
    {
      title: 'Firma Adı',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Vergi Kimlik No',
      dataIndex: 'taxNumber',
      key: 'taxNumber',
    },
    {
      title: 'Vergi Dairesi',
      dataIndex: 'taxOffice',
      key: 'taxOffice',
    },
    {
      title: 'Şehir',
      dataIndex: 'province',
      key: 'province',
    },
  ];

  const fetchCustomers = async (search = '', page = 1) => {
    try {
      setIsLoadingCustomers(true);
      const customersData = await customerApi.fetchCustomers({search, page});
      setCustomers(customersData.data);
      setCustomerCount(customersData.totalDataCount);
    } finally {
      setIsLoadingCustomers(false);
    }
  };

  const searchCustomer = (text: string) => {
    setSearchText(text);
    setPagingPage(1);
    fetchCustomers(text, 1);
  };
  const onChangePage = (page: number) => {
    setPagingPage(page);
    fetchCustomers(searchText, page);
  };

  const routeToCustomerDetail = (taxOffice: string, taxNumber: number) => {
    if (!taxOffice || !taxNumber) {
      notification.warning({
        message: 'Eksik Bilgi',
        description: 'Müşterinin VKN/TCKN ve Vergi Dairesi bilgilerini kontrol ediniz.',
      });
    } else {
      history.push(PATHS.getCustomerDetailPath(taxOffice, taxNumber));
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <ContentContainer maxWidth={900}>
      <Text type="h2">Müşterilerim</Text>
      <Input.Search onChange={(e) => searchCustomer(e.target.value)} placeholder="Müşteri ara" />

      <Table
        onRow={(record) => {
          return {
            onClick: () => {
              routeToCustomerDetail(record.taxOffice, record.taxNumber);
            },
          };
        }}
        className="mt cursor-pointer"
        rowKey="id"
        dataSource={customers}
        pagination={{
          total: customerCount,
          current: pagingPage,
          onChange: onChangePage,
        }}
        columns={tableCols}
        loading={isLoadingCustomers}
      />
    </ContentContainer>
  );
};

export default CustomerList;
