import React, {FC, useEffect, useState} from 'react';
import {Col, Divider, Form, Input, notification, Row, Select, Spin} from 'antd';
import {Label} from 'recharts';
import moment from 'moment';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import InputPrice from '../../../../components/InputPrice';
import Upload from '../../../../components/Upload';
import ProgressStep from '../../../../components/ProgressStep';
import {convertFileToAntdFile, FilePromise, getBase64, normFile} from '../../../../utils/file';
import {ICustomerCreateComponent} from '../types';
import {useAppDispatch, useAppSelector} from '../../../../hooks/useRedux';
import customerApi from '../../../../Api/Customer';
import {convertDotSeperatedToFloat, convertNumberDotSeperated} from '../../../../utils/string';
import {
  ICustomerWarrantOtherInfo,
  IDbs,
  IGuaranteeLetter,
  ILien,
} from '../../../../Api/Customer/types';
import {distortValue} from '../../../../utils';
import {IApiImage} from '../../../../Api/types';
import searchApi from '../../../../Api/Search';
import {fetchBanks} from '../../../../store/globals/globalsActions';
import DatePicker from '../../../../components/DatePicker';

const WarrantInfo: FC<ICustomerCreateComponent> = ({onSubmit, onCancel}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [warrantData, setWarrantData] = useState<any>(null);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const customer = useAppSelector((state) => state.customer.customerCreateQueryData);
  const {banks, isLoadingBanks} = useAppSelector((state) => state.globals);

  const fetchInitialData = async () => {
    const initialData = await customerApi.fetchCustomerWarrantInfo(customer.accountId);
    setWarrantData(initialData);
    const documents = await Promise.all(
      initialData.guaranteeLetters.map(
        (garanteeLetter) => garanteeLetter && FilePromise(garanteeLetter),
      ),
    );
    const guaranteeLetterDocuments = documents.map((file) => convertFileToAntdFile(file as File));
    const guaranteeLetterInfos = distortValue(
      initialData.guaranteeLetterInfos,
      'guaranteeFund',
      convertNumberDotSeperated,
    );

    const mappedLetterInfos = guaranteeLetterInfos.map((letter) => {
      try {
        console.log(letter);
        return {...letter, termDate: moment(letter.termDate)};
      } catch {
        return {...letter, termDate: undefined};
      }
    });

    const initialValues = {
      guaranteeLetterInfos: mappedLetterInfos,
      dbsList: distortValue(initialData.dbsList, 'value', convertNumberDotSeperated),
      liens: distortValue(initialData.liens, 'lienAmount', convertNumberDotSeperated),
      otherInfos: distortValue(initialData.otherInfos, 'value', convertNumberDotSeperated),
      letter1: guaranteeLetterDocuments[0] ? [guaranteeLetterDocuments[0]] : [],
      letter2: guaranteeLetterDocuments[1] ? [guaranteeLetterDocuments[1]] : [],
      letter3: guaranteeLetterDocuments[2] ? [guaranteeLetterDocuments[2]] : [],
    };

    if (!initialValues.dbsList.length) initialValues.dbsList = [{}];
    if (!initialValues.guaranteeLetterInfos.length)
      initialValues.guaranteeLetterInfos = [{termDate: undefined}];
    if (!initialValues.liens.length) initialValues.liens = [{}];
    if (!initialValues.otherInfos.length) initialValues.otherInfos = [{}];

    form.setFieldsValue(initialValues);
    setIsLoadingInitialData(false);
  };

  const handleOnSubmit = async (formValues: any) => {
    try {
      setIsLoadingSubmit(true);
      const guaranteeLetterInfos = formValues?.guaranteeLetterInfos[0]?.guaranteeFund
        ? (distortValue(
            formValues.guaranteeLetterInfos,
            'guaranteeFund',
            convertDotSeperatedToFloat,
          ) as IGuaranteeLetter[])
        : [];

      const liens = formValues?.liens[0]?.lienAmount
        ? (distortValue(formValues.liens, 'lienAmount', convertDotSeperatedToFloat) as ILien[])
        : [];

      const otherInfos = formValues?.otherInfos[0]?.value
        ? (distortValue(
            formValues.otherInfos,
            'value',
            convertDotSeperatedToFloat,
          ) as ICustomerWarrantOtherInfo[])
        : [];

      const dbsList = formValues?.dbsList[0]?.value
        ? (distortValue(formValues.dbsList, 'value', convertDotSeperatedToFloat) as IDbs[])
        : [];

      const guaranteeLetterDocuments =
        formValues?.letter1[0] || formValues?.letter2[0] || formValues?.letter1[0]
          ? ((await Promise.all([
              formValues?.letter1[0]?.originFileObj &&
                getBase64(formValues?.letter1[0]?.originFileObj),
              formValues?.letter2[0]?.originFileObj &&
                getBase64(formValues?.letter2[0]?.originFileObj),
              formValues?.letter3[0]?.originFileObj &&
                getBase64(formValues?.letter3[0]?.originFileObj),
            ])) as IApiImage[])
          : [];

      await customerApi.uploadCustomerWarrantInfo({
        accountId: customer.accountId,
        guaranteeLetterDocuments: guaranteeLetterDocuments.filter((doc) => doc !== undefined),
        guaranteeLetterInfos,
        liens,
        dbsList,
        otherInfos,
      });
      onSubmit();
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
    dispatch(fetchBanks());
  }, []);

  const handleDeleteDocument = (file: any, key: any) => {
    searchApi.deleteDocument(file.tciDoumentId).then((res) => {
      console.log(res, 'res');
      notification.success({message: 'İşlem Başarılı', description: 'Dosya silindi yanıtı'});
      form.setFieldsValue({[key]: []});
    });
  };

  return (
    <Spin spinning={isLoadingInitialData}>
      <ProgressStep className="mb-big" title="Teminat Bilgileri" value={3} steps={8} />

      <Text type="h2" bold>
        Teminat Bilgileri
      </Text>

      <Form
        form={form}
        name="warrantInfo"
        layout="vertical"
        requiredMark={false}
        initialValues={{company: customer.title}}
        onFinish={handleOnSubmit}
        scrollToFirstError
      >
        <Row>
          <Col span={10}>
            <Form.Item name="company" label="Firma Adı">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Text type="h3" bold>
          1. Teminat Mektubu
        </Text>

        <Form.List name="guaranteeLetterInfos">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name, ...restField}, idx) => (
                <Row gutter={20} key={idx}>
                  <Col span={5}>
                    <Form.Item {...restField} label="Tutar" name={[name, 'guaranteeFund']}>
                      <InputPrice placeholder="Örn. 123.234,00" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item {...restField} label="Banka" name={[name, 'bankName']}>
                      <Select
                        options={banks.map(({label}) => ({Label, value: label}))}
                        loading={isLoadingBanks}
                        placeholder="Örn. Akbank"
                        optionFilterProp="label"
                        showSearch
                      />
                    </Form.Item>
                  </Col>

                  <Col span={5}>
                    <Form.Item {...restField} label="Vade" name={[name, 'termDate']}>
                      <DatePicker />
                    </Form.Item>
                  </Col>
                  {idx !== 0 && (
                    <Col span={2}>
                      <Form.Item label=" ">
                        <Button type="dashed" onClick={() => remove(name)} danger>
                          -
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              ))}
              <Row>
                <Col span={15}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Row className="mt" gutter={20}>
          <Col>
            <Form.Item name="letter1" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                label="PDF Yükle"
                onRemove={() => handleDeleteDocument(warrantData.guaranteeLetters[0], 'letter1')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="letter2" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                label="PDF Yükle"
                onRemove={() => handleDeleteDocument(warrantData.guaranteeLetters[1], 'letter2')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="letter3" valuePropName="fileList" getValueFromEvent={normFile}>
              <Upload
                label="PDF Yükle"
                onRemove={() => handleDeleteDocument(warrantData.guaranteeLetters[2], 'letter3')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Text type="h3" bold>
          2. DBS
        </Text>

        <Form.List name="dbsList">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name, ...restField}, idx) => (
                <Row gutter={20}>
                  <Col span={5}>
                    <Form.Item {...restField} label="Tutar" name={[name, 'value']}>
                      <InputPrice placeholder="Örn. 123.234,00" />
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item {...restField} label="Banka" name={[name, 'bankName']}>
                      <Select
                        options={banks.map(({label}) => ({Label, value: label}))}
                        loading={isLoadingBanks}
                        placeholder="Örn. Akbank"
                        optionFilterProp="label"
                        showSearch
                      />
                    </Form.Item>
                  </Col>
                  {idx !== 0 && (
                    <Col span={2}>
                      <Form.Item label=" ">
                        <Button type="dashed" onClick={() => remove(name)} danger>
                          -
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              ))}
              <Row>
                <Col span={10}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Divider />

        <Text type="h3" bold>
          3. İpotek
        </Text>

        <Form.List name="liens">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name, ...restField}, idx) => (
                <Row gutter={20}>
                  <Col span={5}>
                    <Form.Item {...restField} label="Tutar" name={[name, 'lienAmount']}>
                      <InputPrice placeholder="Örn. 123.234,00" />
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item {...restField} label="Açıklama" name={[name, 'description']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  {idx !== 0 && (
                    <Col span={2}>
                      <Form.Item label=" ">
                        <Button type="dashed" onClick={() => remove(name)} danger>
                          -
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              ))}
              <Row>
                <Col span={16}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Divider />

        <Text type="h3" bold>
          4. Diğer
        </Text>

        <Form.List name="otherInfos">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name, ...restField}) => (
                <Row gutter={20}>
                  <Col span={5}>
                    <Form.Item {...restField} label="Tutar" name={[name, 'value']}>
                      <InputPrice placeholder="Örn. 123.234,00" />
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item {...restField} label="Açıklama" name={[name, 'description']}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item label=" ">
                      <Button type="dashed" onClick={() => remove(name)} danger>
                        -
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span={16}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + Ekle
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Row gutter={20}>
          <Col span={9}>
            <Form.Item className="m-0">
              <Button type="primary" htmlType="submit" size="large" loading={isLoadingSubmit} block>
                Kaydet ve Devam Et
              </Button>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item className="m-0">
              <Button
                type="default"
                size="large"
                loading={isLoadingSubmit}
                onClick={onCancel}
                block
              >
                Kaydet ve Çık
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default WarrantInfo;
