/* eslint no-bitwise: "off" */
/* eslint no-nested-ternary: "off" */

import {DefaultTheme} from 'styled-components';

// some options may be initialized in the "croco.config.js"
// these options are marked with comments -> "//craco.config"
// please make same changes on craco.config.js

export const colors = {
  primary: '#09598c', // craco.config
  primaryLight: '#91d5ff',
  primaryDark: '#0050b3',
  primaryFaded: '#f2faff',

  secondary: '#722ed1',
  secondaryLight: '#d3adf7',
  secondaryDark: '#531dab',

  background: '#fff',
  text: '#202020',

  red: '#f5222d',
  volcano: '#fa541c',
  orange: '#ff8300',
  gold: '#FFE8A3',
  yellow: '#fadb14',
  lime: '#a0d911',
  green: '#5ad363',
  cyan: '#13c2c2',
  blue: '#00a6fb',
  geekblue: '#1a73e8',
  purple: '#9400ff',
  magenta: '#eb2f96',
  light: '#fff',
  dark: '#000',
  smoke: '#727272',
  lightSmoke: '#f4f4f4',
  surface: '#333333',
  transparent: 'transparent',
  inherit: 'inherit',

  darkTransparent: '#00000070',
  lightTransparent: '#ffffff42',
};

export type Color = keyof typeof colors;

const getThemedColor = (color: Color | undefined, themeObj: DefaultTheme): string => {
  const validColor: Color = color || 'dark';
  return themeObj.colors[validColor];
};
const createAnimation = (duration = 0.3): string => `${duration}s cubic-bezier(.25,.8,.25,1)`;

// ** INDEX **
// COLORS
// MAIN
// COMPONENTS
// PAGES
// FUNCTIONS

const theme = {
  // MAIN
  colors,
  main: {
    xsMaxSize: 576,
    smMinSize: 576,
    mdMinSize: 768,
    lgMinSize: 992,
    xlMinSize: 1200,
    xxlMinSize: 1600,

    limitedContentWidth: 1425,
    // innerLimitedContentWidth: 700,

    shadow:
      '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
    shadowHover:
      '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);',
    defaultAnimation: createAnimation(),

    margin: 10,
    marginBig: 40,
    padding: 10,
    paddingBig: 40,
    borderRadius: 10,

    textFontWeight: 400,
    titleFontWeight: 500,
    textFontSize: '16px', // craco.config
    h1FontSize: '30px', // value type is string becouse mobile theme units are 'vw' - types should match
    h2FontSize: '25px',
    h3FontSize: '20px',
    h4FontSize: '15px',
    h5FontSize: '13px',
    hugeFontSize: '60px',
    largeFontSize: '40px',
    bigFontSize: '35px',
  },

  // COMPONENTS
  header: {
    height: 96,
    horizontalPadding: 40,
  },
  content: {
    paddingHorizontal: '1vw',
  },
  pageContent: {
    paddingHorizontal: 20,
  },

  card: {
    background: colors.light,
    margin: 10,
    padding: 20,
    borderRadius: 5,
  },

  // PAGES

  // FUNCTIONS
  getThemedColor(color: Color | undefined): string {
    return getThemedColor(color, theme);
  },
  createAnimation,
};

export const lightenColor = (color: Color, strength = 0.4): string => {
  // strength 0 - 1
  const themedColor = theme.getThemedColor(color);

  const num = parseInt(themedColor.replace('#', ''), 16);
  const amt = Math.round(2.55 * strength * 100);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

export default theme;
export type Theme = typeof theme;
